import React, { useState, useEffect } from 'react'
import Filter from '../../../helper/filter/Filter'
import CampaignTable from './CampaignTable'

function Index() {
    const [campaignId, setcampaignId] = useState("")
    const [fromDate, setfromDate] = useState(localStorage.getItem("fromDate") || '');
    const [toDate, settoDate] = useState(localStorage.getItem("toDate") || '');
    const [campaignNamesArr, setCampaignNamesArr] = useState([])
    const campaignIdHandler = (e) => {
        setcampaignId(e)
    }

    const dateHandler = (e) => {
        if (e) {
            setfromDate(e[0]);
            settoDate(e[1]);
            setCampaignNamesArr([])
        }
    }

    useEffect(() => {
        localStorage.setItem('fromDate', fromDate);
        localStorage.setItem('toDate', toDate);
    }, [fromDate, toDate])
    return (
        <>

            <div className='bg-white'>
                <Filter order={true} campaignIdHandler={campaignIdHandler} dateHandler={dateHandler} fromDate={fromDate} toDate={toDate} dateShow={true} />
            </div>
            <div className="middle_container sb_container">
                <CampaignTable campaignId={campaignId} fromDate={fromDate} toDate={toDate} campaignNamesArr={campaignNamesArr} setCampaignNamesArr={setCampaignNamesArr} />
            </div>
        </>
    )
}

export default Index