import React, { useContext, useEffect, useState } from "react";
import "rsuite/dist/rsuite.min.css";
import { ProfileContext } from "../../usecontext/useContext";
import { ApiOrderSave } from "../../api-wrapper/campaign/ApiCampaignName";
import { DateRangePicker, Dropdown, Toast, moment, useNavigate } from '../links/link'

function Filter(props) {
  let { Id, setOrderSwitch, orderSwitch, orderVal, setOrderVal } = useContext(ProfileContext);
  let navigate = useNavigate();
  const [date, setDate] = useState();
  const [timezone, setTimezone] = useState(
    localStorage.getItem("antonTimezone")
  );

  const [profileId, setprofileId] = useState(
    localStorage.getItem("profileId") || ""
  );

  useEffect(() => {
    if (localStorage.getItem("fromDate") && localStorage.getItem("toDate")) {
      setDate([
        localStorage.getItem("fromDate"),
        localStorage.getItem("toDate"),
      ]);
    } else {
      setDate([
        moment(new Date()).startOf("month")?.format("YYYY-MM-DD"),
        moment(new Date())
          .tz(timezone && timezone)
          ?.format("YYYY-MM-DD"),
      ]);
    }
  }, []);

  useEffect(() => {
    if (props?.dateHandler) {
      props?.dateHandler(date);
    }

  }, [date]);

  const logOutHandler = () => {
    localStorage.removeItem("sellerToken");
    localStorage.removeItem("profileId");
    localStorage.removeItem("fromDate");
    localStorage.removeItem("toDate");
    localStorage.removeItem("userName");
    localStorage.removeItem("radio");
    localStorage.removeItem("perPage");
    localStorage.removeItem("selectedItem");

    navigate("/login");
  };

  const dateChangeHandler = (e) => {
    let arr;
    if (e?.length == 0) {
      arr = [];
    } else {
      arr = [
        moment(e && e[0]).format("YYYY-MM-DD"),
        moment(e && e[1]).format("YYYY-MM-DD"),
      ];
    }

    setDate(arr);
  };


  const HandleOrderSave = () => {

    let data = {
      profileId: profileId,
      defaultOrder: orderVal
    }
    ApiOrderSave(data)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);

        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("somthing went wrong!!");
      });
  }

  return (
    <div className="filter_section">

      <div className="inner_filer date_range me-0 me-md-4 mb-3 mb-md-0">
        {
          props.dateShow && <DateRangePicker
            className="filter_date"
            placeholder="Select Date"
            onChange={(e) => dateChangeHandler(e)}
            format="yyyy-MM-dd"
            value={[
              date &&
              date[0] !== undefined &&
              moment(date[0], "YYYY-MM-DD").toDate(),

              date &&
              date[1] !== undefined &&
              moment(date[1], "YYYY-MM-DD").toDate(),
            ]}
            placement="bottomEnd"
          />
        }

      </div>
      {
        props.order &&
        <div className="inner_filter d-flex justify-content-xl-center align-items-center">
          <div className=" form-check form-switch me-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="isActive"
              onChange={(event) => setOrderSwitch(event.target.checked)}
              checked={orderSwitch}
              value={orderSwitch}
              style={{ width: "50px", height: "20px" }}
            />
          </div>
          <div className=" order_input">
            <input
              type="text"
              name="order"
              onChange={(event) => setOrderVal(event.target.value)}
              value={orderVal}

            />

            <button className="add_filter order-btn  btn btn-primary" type="submit" onClick={() => HandleOrderSave()}>Save</button>

          </div>

        </div>

      }


      <div className="inner_filter d-lg-flex justify-content-xl-center ms-4">
        <div className="profile_section">
          <Dropdown>
            <Dropdown.Toggle className="dropdown-btn" clad id="dropdow-basic">
              <span className="me-2">
                {localStorage.getItem("userName") || "Username"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => logOutHandler()}>
                LogOut
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default Filter;
