import React, { useEffect, useState } from "react";
import { childBidHandler } from "../../../redux/action";
import { useSelector, useDispatch, DataTable } from "../../../helper/links/link";

function ExpandTable({
    arg,
    setArg,
    placementData,
    campaignData,
    setsubval,
    minTargetCPC,
    maxTargetCPC,
    campaignName,
    order,
    aovData,
    limitValue,
    setBulk,
    bulk,
    setcampaignData,
    TOSPercentage,
    defaultTargetAcos,
    HandleSelect,
    expandFlag,
    setRowSelect,
    rowSelect,
    handleApplyBestCpc,
    bestFlag,
    bestArr,
    handleSelectTarget,
    setChilddata,
    bestCVRCPC
}) {
    const dispatch = useDispatch();
    const [weekFilter, setweekFilter] = useState(0);
    const [subData, setSubData] = useState([]);
    const currentbid = useSelector(state => state.data.currentbid)
    let [flag, setflag] = useState(false)


    useEffect(() => {
        placementData?.map((el, index) => {
            if (el.targetAcos == 0 || el.targetAcos == undefined) {
                let a = campaignName.split(/\s+/);

                function isWordIncludedInObject(word, obj) {
                    return word.type.toLowerCase().includes(obj.toLowerCase());
                }
                let find = defaultTargetAcos?.find(word => a.some(obj => isWordIncludedInObject(word, obj)));
                if (find == undefined) {
                    find = defaultTargetAcos?.find(x => x.type == "All other")
                }


                placementData[index].targetAcos = find?.targetAcos;


            }
            else {
                placementData[index].targetAcos = el.targetAcos;
            }
        })

        setSubData(placementData);
    }, [placementData]);



    const columns1 = [
        {
            name: "",
            width: "50px",
            cell: (row) => {
                let arr = JSON.parse(localStorage.getItem('SDtarget')) || [];
                // if (row.clicks > 4 && (row?.limitedCPC != row?.tagetCPCAmazon)) {

                // 	if (!arr.includes(row.targetId)) {
                // 		arr.push(row.targetId);
                // 		localStorage.setItem('SDtarget', JSON.stringify(arr));
                // 	}
                // }

                return (
                    <input
                        type="checkbox"
                        defaultChecked={arr.includes(row.targetId)}
                        onChange={(e) => handleSelectTarget(e, row.targetId)}
                    />
                );

            }
        },
        {
            name: "Target Acos",
            selector: (row) => row.targetAcos,
            cell: (e, rowIndex) => {

                const changeEvent = ($event) => {

                    let eventValue = Number($event.target.value)

                    let crrObj = subData;
                    let acosVal = eventValue;
                    let TotalSales = e.sales;
                    let TotalClick = e.clicks;
                    let result;
                    let Sales = 0
                    let Units = 0
                    // --applying condition if placement order=0 && clicks>=4
                    if (subData) {
                        for (let i = 0; i < subData.length; i++) {
                            Sales = Sales + subData[i].sales;
                            Units = Units + subData[i].orders;
                        }
                    }

                    let AOV //if AOV  is set on setting page then, take that AOV, ELSE AOV =sales/units
                    if (order == 0) {
                        aovData.forEach(element => {
                            let uppercase = element.campaignName.toUpperCase()
                            let name = new RegExp(uppercase, 'i')
                            if (name.test(campaignName)) {
                                AOV = element.value
                            }
                        });
                    }
                    else {
                        AOV = Sales / Units
                    }

                    if (!AOV) {
                        AOV = Sales / Units
                    }


                    //target cpc calculations conditions
                    if (crrObj[rowIndex].orders == 0 && crrObj[rowIndex].clicks >= 4) { //if placement level , order=0 and clicks>= 4 
                        result = (1 / (crrObj[rowIndex].clicks + 1)) * (AOV) * (acosVal / 100)
                    }

                    else if (crrObj[rowIndex].sales == 0 && crrObj[rowIndex].clicks < 4) {
                        result = (Number(AOV) / (Number(crrObj[rowIndex].clicks) + 1)) * (eventValue / 100)
                    }

                    else { // else
                        if (crrObj[rowIndex].orders <= 3 && crrObj[rowIndex].cvr >= 50) { //if orders <=0 and cvr>=50
                            result = crrObj[rowIndex].cpc + (crrObj[rowIndex].cpc * 0.5) // old cpc+ 50% oldcpc
                        }
                        else {// rest placements 
                            result = (TotalSales / TotalClick) * (eventValue / 100);
                        }
                    }

                    let finalVal = result.toFixed(2)

                    if (isNaN(finalVal)) {
                        finalVal = 0
                    }

                    // condition of min and max target cpc according to setting page
                    crrObj[rowIndex].targetAcos = eventValue == '' ? 0 : eventValue;

                    // if (bestArr.includes(e.campaignId)) {

                    // 	crrObj[rowIndex].targetCPC = crrObj[rowIndex].bestCPC
                    // }
                    // else if (bestCVRCPC.includes(e.campaignId)) {
                    // 	crrObj[rowIndex].targetCPC = crrObj[rowIndex].bestCVRCPC
                    // }
                    // else {
                    crrObj[rowIndex].targetCPC = eventValue != '' ? Number(finalVal) < minTargetCPC ? minTargetCPC : Number(finalVal) > maxTargetCPC ? maxTargetCPC : Number(finalVal) : 0;
                    // }
                    setSubData([...subData]);


                    // ------------tatgetcpc condtions---------//
                    HandleTargetCpcCondition(crrObj, rowIndex)





                    // // --------- new limit calculation (cpc and limited cpc) ------------//
                    // calLimiatedCpcANDtagetcpcAmazon(e, e.tagetCPCAmazon)


                    // ----------- update campaign data --------//
                    dispatch(childBidHandler())
                }

                return (
                    <>
                        <input onChange={changeEvent} onBlur={setflag(false)} value={e.targetAcos} type="text" className="cal" />
                    </>
                );
            },

        },
        {
            name: "Campaigns",
            selector: (row) => row.targetName,
            sortable: true,
            cell: (e) => {
                return <>
                    {
                        e.targetName ?
                            <div className={e.targetStatus == "ENABLED" ? "green_dot dot" : "red_dot dot"}>
                                <p></p>
                                <div>{e.targetName}</div>
                            </div>
                            :
                            "-"
                    }

                </>;
            },
        },
        {
            name: "Target CPC In Amazon",
            sortable: true,
            width: "80px",
            selector: (row) => `$${row.tagetCPCAmazon}`,

        },
        {
            name: "Target CPC",
            sortable: true,
            selector: (row) => `$${row.targetCPC}`,
        },
        {
            name: "Best CPC",
            sortable: true,
            selector: (row) => row.targetCVRCPC ? row.targetCVRCPC == 1 ? row.targetCPC : row.bestCVRCPC : 0,
            cell: (e) => {
                if (bestArr.includes(e.campaignId)) {
                    return e.bestCPC || 0;
                }
                else {
                    return e.targetCVRCPC == 1 ? e.targetCPC || 0 : e.bestCVRCPC || 0;
                }
            }
        },


        {
            name: 'Limited CPC',
            selector: (row) => `$${row.limitedCPC}`,
            cell: (e) => {
                const changeEvent = ($event) => {
                    let eventValue = Number($event.target.value)
                    e.limitedCPC = eventValue;
                    dispatch(childBidHandler())
                }
                return (

                    <>
                        <input onChange={changeEvent}
                            value={e.limitedCPC || 0}
                            type="number"
                            className="cal"
                            inputMode="numeric"
                        />
                    </>
                )
            }
        },
        {
            name: "Impressions",
            selector: (row) => row.impressions,

        },
        {
            name: "Clicks",
            selector: (row) => row.clicks,
        },
        {
            name: "CTR%",
            selector: (row) => row.ctr,
            cell: (e) => (
                `${e.ctr}%`
            )
        },
        {
            name: "Spend",
            selector: (row) => row.spend,
            cell: (e) => (
                `$${e.spend}`
            )
        },
        {
            name: "Sales",
            selector: (row) => row.sales,
            cell: (e) => (
                `$${e.sales}`
            )

        },
        {
            name: "Orders",
            selector: (row) => row.orders,
        },
        {
            name: "Units",
            selector: (row) => row.units,
        },

        {
            name: "CVR%",
            selector: (row) => row.cvr,
            cell: (e) => (
                `${e.cvr}%`
            )
        },
        {
            name: "ACOS%",
            selector: (row) => row.acos,
            cell: (e) => (
                `${e.acos}%`
            )
            // width: "70px",
        },
        {
            name: "CPC",
            selector: (row) => row.cpc,
            cell: (e) => (
                `$${e.cpc}`
            )
        },
        {
            name: "ROAS",
            selector: (row) => row.roas,
            // width: "70px",
        },
    ];


    const cal = () => {

        if (placementData?.length != 0 && placementData != undefined) {

            let crrObj = placementData;
            let Sales = 0
            let Units = 0

            for (let i = 0; i < crrObj?.length; i++) {
                Sales = Sales + crrObj[i].sales;
                Units = Units + crrObj[i].orders;
            }
            let AOV

            crrObj?.map((e, rowIndex) => {

                let eventValue = e.targetAcos;
                let TotalSales = e.sales;
                let TotalClick = e.clicks;

                let result;

                if (order == 0) {
                    aovData.forEach(element => {
                        let uppercase = element.campaignName.toUpperCase()
                        let name = new RegExp(uppercase, 'i')
                        if (name.test(campaignName)) {
                            AOV = element.value
                        }
                    });
                }
                else {
                    AOV = Sales / Units
                }

                if (!AOV) {
                    AOV = Sales / Units
                }

                // --applying condition if placement order=0 && clicks>=4
                // If Orders = < 3 and CVR is >= 50% then use formula: (Old CPC + 50%)

                if (crrObj[rowIndex].orders == 0 && crrObj[rowIndex].clicks >= 4) {
                    result = (1 / (crrObj[rowIndex].clicks + 1)) * (AOV) * (eventValue / 100)
                }

                else if (crrObj[rowIndex].sales == 0 && crrObj[rowIndex].clicks < 4) {
                    result = (Number(AOV) / (Number(crrObj[rowIndex].clicks) + 1)) * (eventValue / 100)
                }
                else {
                    if (crrObj[rowIndex].orders <= 3 && crrObj[rowIndex].cvr >= 50) {
                        result = crrObj[rowIndex].cpc + (crrObj[rowIndex].cpc * 0.5)
                    }
                    else {
                        result = (TotalSales / TotalClick) * (eventValue / 100);
                    }
                }

                let finalVal = Number(result.toFixed(2)) || 0
                if (isNaN(finalVal)) {
                    finalVal = 0
                }


                // if (bestArr.includes(e.campaignId)) {

                // 	crrObj[rowIndex].targetCPC = crrObj[rowIndex].bestCPC
                // }

                // else {
                crrObj[rowIndex].targetCPC = eventValue != '' ? Number(finalVal) < minTargetCPC ? minTargetCPC : Number(finalVal) > maxTargetCPC ? maxTargetCPC : Number(finalVal) : 0
                // }


                // ------------tatgetcpc condtions---------//
                HandleTargetCpcCondition(crrObj, rowIndex)

            })


            setChilddata(crrObj)
            setflag(false)
            dispatch(childBidHandler())
        }
    }

    // ------------tatgetcpc condtions-----------------//
    const HandleTargetCpcCondition = (crrObj, rowIndex) => {

        if (rowIndex == 2) {
            let place = crrObj;
            let all = 0;
            let one = 0;
            let two = 0;
            let placementWithClicksAndSale = {}
            let placementWithoutClicksAndSale = []

            for (let i = 0; i < place.length; i++) {

                if (place[i].clicks < 4 && place[i].orders == 0) {
                    all++;
                }
                if (place[i].clicks > 0 && place[i].orders > 0) {
                    one++;
                }
                else if (place[i].clicks >= 4 && place[i].orders == 0) {
                    one++
                }
            }

            if (one == 1) {
                for (let i = 0; i < place.length; i++) {
                    if (place[i].clicks > 0 && place[i].orders > 0) {
                        placementWithClicksAndSale = place[i]
                    }
                    else if (place[i].clicks >= 4 && place[i].orders == 0) {
                        placementWithClicksAndSale = place[i]
                    }
                    if (place[i].clicks < 4 && place[i].orders == 0) {
                        two++;
                        placementWithoutClicksAndSale.push(place[i])
                    }
                }
            }

            if (all == 3) {
                setflag(true)

            }
            else if (one == 1 && two == 2) {
                placementWithoutClicksAndSale.map((el, i) => {
                    let index = crrObj?.findIndex(x => x == el)
                    crrObj[index].targetCPC = placementWithClicksAndSale.targetCPC
                })
                setflag(true)
            }
            else {
                place.map((el, i) => {
                    if (place[i].orders == 0 && place[i].clicks < 4) {
                        let lowestCPC = Math.min(
                            place[(i + 1) % place.length].targetCPC || 0,
                            place[(i + 2) % place.length].targetCPC || 0
                        );
                        crrObj[i].targetCPC = lowestCPC
                    }
                })
                setflag(true)
            }
        }

        crrObj.map((el, i) => {
            calLimiatedCpcANDtagetcpcAmazon(el)
        })

    }

    // --------- targetcpcamazon and limitedcpc ------------//
    const calLimiatedCpcANDtagetcpcAmazon = (e, targetCPCAmazon, cpc) => {

        //  targetcpcamazon (calculate based on keywordbid(new bidadjustemnt main))//
        let targetCpcAmazon;
        let queryResult = (Number(e.bid) * Number(e.limitedCPC || 0)) / 100;
        let finalResult = Number(queryResult) + Number(e.bid);
        // e.tagetCPCAmazon = finalResult ? finalResult.toFixed(2) : 0;
        e.tagetCPCAmazon = e.bid ? e.bid.toFixed(2) : 0;
        // targetCpcAmazon = finalResult ? finalResult.toFixed(2) : 0;

        targetCpcAmazon = e.tagetCPCAmazon;
        // limitedcpc (calculate based on targetcpcamazon) //

        let limitedCPCValue = 0;
        const M7 = parseFloat(targetCpcAmazon);
        const N7 = parseFloat(e.targetCPC);
        const acosRange = limitValue?.find(x => e.acos >= x.from && e.acos <= x.to)
        const H3 = acosRange?.increaseBid;
        const H4 = acosRange?.decreaseBid;

        function calculateResult(M7, N7, H3, H4) {
            if (M7 <= N7) {
                return Math.min(M7 * (1 + H3 / 100), N7);
            } else {
                return Math.max(M7 * (1 - H4 / 100), N7);
            }
        }

        limitedCPCValue = calculateResult(M7, N7, H3, H4);
        e.limitedCPC = limitedCPCValue

        let val = Number(limitedCPCValue) < minTargetCPC ? minTargetCPC : Number(limitedCPCValue) > maxTargetCPC ? maxTargetCPC : Number(limitedCPCValue)
        e.limitedCPC = val.toFixed(2)
    }


    useEffect(() => {
        if (placementData != undefined) {
            cal(placementData)
        }

    }, [placementData]);


    useEffect(() => {
        if (placementData != undefined) {
            placementData?.map((crrObj, rowIndex) => {

                let arr = JSON.parse(localStorage.getItem('SDtarget')) || [];
                if (crrObj.clicks > 4 && (crrObj?.limitedCPC != crrObj?.tagetCPCAmazon)) {

                    if (!arr.includes(crrObj.targetId)) {
                        arr.push(crrObj.targetId);
                        localStorage.setItem('SDtarget', JSON.stringify(arr));
                    }
                }

                if (bestArr.includes(crrObj.campaignId)) {
                    let bestCPC = crrObj.targetCVRCPC == 1 ? crrObj.targetCPC : crrObj.bestCVRCPC;
                    crrObj.bestCPC = bestCPC;
                    crrObj.limitedCPC = bestCPC >= crrObj.targetCPC ? bestCPC : crrObj.targetCPC;
                    crrObj.targetCPC = bestCPC >= crrObj.targetCPC ? bestCPC : crrObj.targetCPC;
                }
            })
        }
    }, [placementData, bestArr]);



    return (
        <>
            <DataTable
                className="table_content"
                columns={columns1}
                data={subData}
                fixedHeader
                loading={true}
                progressPending={false}
            />

        </>
    );
}

export default ExpandTable;
