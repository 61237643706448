import React, { useEffect, useState } from "react";
import Filter from "../../../../helper/filter/Filter";
import { Toast, DataTable } from '../../../../helper/links/link';
import { ApiPlacementListMange, ApiUnmange } from "../../../../api-wrapper/campaign/ApiCampaignName";
function UnmanageCampaign() {

    const [profileId, setprofileId] = useState(
        localStorage.getItem("profileId") || ""
    );
    const [totalRecords, settotalRecords] = useState(0);
    const [arg, setArg] = useState({
        pageNo: 1,
        perPage: 10,
    });


    const [filter, setFilter] = useState("Sponsored Products");
    const [campaignData, setcampaignData] = useState([]);
    const columns = [
        {
            name: "Campaigns",
            selector: (row) => row.campaignName,
            width: "450px",
            sortable: true,
        },
    ]

    const getCampaignData = async () => {

        if (profileId) {

            let data = {
                profileId: profileId,
                type: filter,
                ...arg


            };

            try {
                const e = await ApiPlacementListMange(data);
                if (e?.isSuccess) {
                    settotalRecords(e.totalRecords);
                    setcampaignData(e.unmanageCampaign);

                } else {
                    Toast.error(e?.message);
                }
            } catch (error) {
                Toast.error("Something went wrong");
            } finally {


            }
        }
    };


    useEffect(() => {
        getCampaignData()
    }, [arg, filter]);

    const HandleUnMange = () => {
        let filterData = selectedRows.selectedRows?.map(el => el.campaignId)
        let data = {
            isManage: true,
            type: filter,
            campaignIds: filterData,
            profileId: profileId,
        };

        ApiUnmange(data)
            .then((res) => {
                if (res.isSuccess) {
                    Toast.success(res.message);
                    getCampaignData()
                    setSelectedRows([])
                }
            }).catch((err) => {
                Toast.error("somthing went wrong!!");
            });
    }


    const [selectedRows, setSelectedRows] = useState([]);
    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <div className="form-check" style={{ backgroundColor: '' }}>
                    <input
                        type="checkbox"
                        className="form-check-input"

                        ref={ref}
                        onClick={onClick}
                        {...rest}
                    />
                    <label className="form-check-label" id="booty-check" />
                </div>
            </>
        )
    })

    const handleSelectedRowsChange = (newSelectedRows) => {
        setSelectedRows(newSelectedRows);
    };

    const handlePageChange = (page) => {


        setArg({ pageNo: page, perPage: arg.perPage })
    };

    const handlePerRowsChange = async (newPerPage, page) => {


        setArg({ pageNo: page, perPage: newPerPage })
    };

    return (
        <div className="bg-white">
            <Filter
                dateShow={false}

            />


            <div className="middle_container heading_none">

                <div className="data_content ">
                    <div>
                    </div>
                    <div className="history-filter" >

                        <select
                            name="sortType"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        >
                            <option value={"Sponsored Products"}>  Sponsored Products  </option>
                            <option value={"Sponsored Brands"}>  Sponsored Brands  </option>
                            <option value={"Sponsored Display"}>  Sponsored Display </option>

                        </select>

                        <button className="blue_btn m-2 float-end" onClick={() => HandleUnMange()}>
                            UnManaged Campaigns
                        </button>

                    </div>
                    <DataTable
                        className="table_content"
                        columns={columns}
                        data={campaignData}
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        onSelectedRowsChange={handleSelectedRowsChange}
                        pagination
                        paginationServer
                        paginationPerPage={10}
                        onChangePage={(page) => setArg({ pageNo: page, perPage: arg.perPage })}
                        onChangeRowsPerPage={(newPerPage, page) => setArg({ pageNo: page, perPage: newPerPage })}
                        paginationTotalRows={totalRecords}

                    />

                </div>
            </div>
        </div>

    )
}

export default UnmanageCampaign