import axios from "axios";

const api = `${process.env.REACT_APP_API_BASE}/api/SB/target/`
const authToken = localStorage.getItem('sellerToken')

const headers = { Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json' };

const ApiHistory = (data) => {
    return axios.post(`${api}targetLogHistory`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

export { ApiHistory }
