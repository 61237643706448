import React, { useEffect, useState } from 'react';
import nav from '../../../router/nav';
import { useLocation, useNavigate } from '../../../helper/links/link';

function Sidebar({ open, setOpen }) {
    let navigate = useNavigate();
    let location = useLocation();
    const [activePage, setActivePage] = useState('');

    const [subMenuOpen, setSubMenuOpen] = useState('');
    const pageChangeHandler = (el) => {
        if (el?.submenu) {
            setSubMenuOpen(el.path)
        } else {
            setSubMenuOpen('')
        }
        setActivePage(el.path)
        navigate(el.path)
        if (window.innerWidth <= 520) {
            var element = document.getElementById("wrapper");
            element.classList.remove("toggled");
            setOpen(true)
        }
    }

    const subMenuHandler = el => {
        setActivePage(el.path)
        if (el.path == '/advertising/campaign') {
            navigate(el.path)
        }
        if (el.path == '/advertising/history' ||
            el.path == '/advertising/campaign' ||
            el.path == '/settings/acos' ||
            el.path == '/settings/other' ||
            el.path == '/settings/unmange-campaign'

        ) {
            navigate(el.path)
        }

    }
    useEffect(() => {
        for (let i = 0; i < nav.length; i++) {
            const dropdownButton = document.querySelector(`.dropdown-btn-${i}`);
            const dropdownContent = document.querySelector(`.dropdown-content-${i}`);

            if (dropdownButton && dropdownContent) {
                dropdownButton.addEventListener("click", function () {
                    dropdownContent.classList.toggle("show");
                });
            }
        }
    }, []);


    return (
        <>
            <aside id='sidebar-wrapper' className='sidebar_container'
                onMouseEnter={() => setOpen(false)} onMouseLeave={() => setOpen(true)}
            >
                <div className="sidebar-head">
                    <li className='sidebar_heading'>
                        <a>
                            <i className='fa fa-bars'></i>Amazon Ads
                        </a>
                    </li>
                </div>

                <ul className="sidebar-nav">
                    {
                        nav?.map((el, i) => {
                            return (
                                <div key={i}>
                                    {el.submenu ? (
                                        <>
                                            <li className={`dropdown-btn-${i} dropdown-btn ${el.path == location.pathname ? 'active' : ''}`}>
                                                <a className="sidebar_link" >{el.icon}{el.name}
                                                    <i className="fa fa-caret-down"></i>
                                                </a>
                                            </li>

                                            <ul className={`dropdown-content-${i} dropdown-content`}>
                                                {el.submenu.map((sub) => {
                                                    return (
                                                        <li className={`${sub.path == location.pathname ? 'active' : ''}`}>
                                                            <a className="sidebar_link" onClick={() => subMenuHandler(sub)}>
                                                                {sub.icon}
                                                                {sub.name}
                                                            </a>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </>
                                    )
                                        :
                                        <li className={`${el.path == location.pathname ? 'active' : ''}`}>
                                            <a className="sidebar_link" onClick={() => { pageChangeHandler(el) }}>{el.icon}{el.name}</a>
                                        </li>
                                    }
                                </div>
                            )
                        })
                    }
                </ul>
            </aside>
        </>
    )
}

export default Sidebar