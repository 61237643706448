import axios from "axios";

const api = `${process.env.REACT_APP_API_BASE}/api/SD/`
const settingApi = `${process.env.REACT_APP_API_BASE}/api/setting/`
const authToken = localStorage.getItem('sellerToken')
const filterApi = `${process.env.REACT_APP_API_BASE}/api/filter/`

const headers = { Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json' };

const ApiCampaignList = (data) => {
    return axios.post(`${api}campaign/getSDCampaign`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiUnmange = (data) => {
    return axios.post(`${settingApi}updateUnmanagedCampaign`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiChild = (data) => {
    return axios.post(`${api}target/getTargetData`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApisaveInAmazon = (data) => {
    return axios.post(`${api}target/updateSDTarget`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

export { ApiCampaignList, ApiUnmange, ApiChild, ApisaveInAmazon }

