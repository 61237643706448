import React, { useEffect, useState } from 'react'
import Filter from '../../../../helper/filter/Filter';
import { ApiSaveAcos, ApiGetAcos, ApiSaveOtherAcos } from '../../../../api-wrapper/setting/ApiSettings';
import { Accordion, Toast } from '../../../../helper/links/link';

function ACOS() {
    const [profileId, setprofileId] = useState(localStorage.getItem("profileId") || "")
    const [val, setVal] = useState([]);
    const [sb, setSB] = useState([]);
    const [sd, setSD] = useState([]);

    const changeHandler = (e, el, name) => {
        if (e.target.type == 'checkbox') {
            el[name] = e.target.checked
        } else {
            if (name === "dynamicPPtargetAcos" || name == 'dynamicTOStargetAcos' || name == 'dynamicROStargetAcos') {
                el.Acos = Number(e.target.value)
            } else {
                el[name] = Number(e.target.value);
            }
        }
        setVal([...val]);
    }

    const saveHandler = (el) => {
        const data = {
            profileId: profileId,
            type: el.type,
            TOStargetAcos: el.TOStargetAcos,
            PPtargetAcos: el.PPtargetAcos,
            ROStargetAcos: el.ROStargetAcos,
            dynamicTOStargetAcos: el.dynamicTOStargetAcos,
            dynamicPPtargetAcos: el.dynamicPPtargetAcos,
            dynamicROStargetAcos: el.dynamicROStargetAcos,
            dynamicTOSTarget: el.dynamicTOSTarget || false,
            dynamicPPTarget: el.dynamicPPTarget || false,
            dynamicROSTarget: el.dynamicROSTarget || false
        }

        ApiSaveAcos(data)
            .then((e) => {
                if (e?.isSuccess) {
                    Toast.success(e?.message);
                } else {
                    Toast.error(e?.message);
                }
            })
            .catch((e) => {
                Toast.error("Somthing went wrong");
            });
    }

    const SBsaveHandler = (el, type) => {
        const data = {
            profileId: profileId,
            type: el.type,
            targetAcos: el.targetAcos,
            campaignType: type

        }

        ApiSaveOtherAcos(data)
            .then((e) => {
                if (e?.isSuccess) {
                    Toast.success(e?.message);
                } else {
                    Toast.error(e?.message);
                }
            })
            .catch((e) => {
                Toast.error("Somthing went wrong");
            });


    }

    const HandleGetData = () => {
        const data = {
            profileId: profileId,
        }
        ApiGetAcos(data)
            .then((e) => {
                if (e?.isSuccess) {

                    setVal(e?.spData)
                    setSB(e.sbData)
                    setSD(e.sdData)

                } else {
                    Toast.error(e?.message);
                }
            })
            .catch((e) => {
                Toast.error("Somthing went wrong");
            });
    }

    useEffect(() => {
        HandleGetData()
    }, []);

    return (

        <>
            <div className='bg-white'>
                <Filter />
            </div>

            <div className='middle_container addfilter_container acos_filter'>
                <div className='data_content data_content_btn'>
                    <div className='data_modal_btn'>
                        <h5>Sponsored Products (Set Default Target ACOS) </h5>
                    </div>
                    <div className=' py-4 px-3'>
                        <table>
                            <tr>
                                <th>Type</th>
                                <th>Top Of Search</th>
                                <th>Dynamic Target ACOS</th>
                                <th>Rest Of Search</th>
                                <th>Dynamic Target ACOS</th>
                                <th>Product Pages</th>
                                <th>Dynamic Target ACOS</th>
                                <th>Action</th>

                            </tr>
                            {
                                val && val?.map(el => {
                                    return (
                                        <>
                                            <tr>
                                                <td><b>{el.type} </b></td>
                                                <td><input type='number' data-name={el.TOStargetAcos} value={el.TOStargetAcos} onChange={(e) => changeHandler(e, el, "TOStargetAcos")} /></td>
                                                <td>
                                                    <div className="form-check form-switch d-flex justify-content-center align-items-center">
                                                        <input type='checkbox' className="form-check-input" checked={el.dynamicTOSTarget} value={el.dynamicTOSTarget} onChange={(e) => changeHandler(e, el, "dynamicTOSTarget")} />
                                                    </div>
                                                </td>
                                                <td><input type='number' data-name={el.ROStargetAcos} value={el.ROStargetAcos} onChange={(e) => changeHandler(e, el, "ROStargetAcos")} /></td>
                                                <td>
                                                    <div className=" form-check form-switch d-flex justify-content-center align-items-center">
                                                        <input type='checkbox' className="form-check-input" checked={el.dynamicROSTarget} value={el.dynamicROSTarget} onChange={(e) => changeHandler(e, el, "dynamicROSTarget")} />
                                                    </div>
                                                </td>
                                                <td><input type='number' data-name={el.PPtargetAcos} value={el.PPtargetAcos} onChange={(e) => changeHandler(e, el, "PPtargetAcos")} /></td>
                                                <td>
                                                    <div className="form-check form-switch d-flex justify-content-center align-items-center">
                                                        <input type='checkbox' className="form-check-input" checked={el.dynamicPPTarget} value={el.dynamicPPTarget} onChange={(e) => changeHandler(e, el, "dynamicPPTarget")} />
                                                    </div>
                                                </td>
                                                <td className='save_btn'><button onClick={() => saveHandler(el)}>Save</button></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={8} className='accordian_css'>
                                                    <Accordion >
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>Top Of Search</Accordion.Header>
                                                            <Accordion.Body>
                                                                <table style={{ width: "100%" }}>
                                                                    <tr>
                                                                        <th>CRV</th>
                                                                        <th>Target ACOS</th>
                                                                    </tr>
                                                                    {
                                                                        el?.dynamicTOStargetAcos && el?.dynamicTOStargetAcos?.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr colSpan={8} key={`dynamicTOStargetAcos_${index}`}>
                                                                                        <td>
                                                                                            {item.from} - {item.to} %
                                                                                        </td>
                                                                                        <td><input type='number' data-name={item._id} value={item.Acos} onChange={(e) => changeHandler(e, item, 'dynamicTOStargetAcos')} /></td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </table>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>Rest Of Search</Accordion.Header>
                                                            <Accordion.Body>
                                                                <table style={{ width: "100%" }}>
                                                                    <tr>
                                                                        <th>CRV</th>
                                                                        <th>Target ACOS</th>
                                                                    </tr>
                                                                    {
                                                                        el?.dynamicROStargetAcos && el?.dynamicROStargetAcos?.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr colSpan={8} key={`dynamicROStargetAcos_${index}`}>
                                                                                        <td>
                                                                                            {item.from} - {item.to} %
                                                                                        </td>
                                                                                        <td><input type='number' data-name={item._id} value={item.Acos} onChange={(e) => changeHandler(e, item, "dynamicROStargetAcos")} /></td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </table>

                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header>Product Pages</Accordion.Header>
                                                            <Accordion.Body>
                                                                <table style={{ width: "100%" }}>
                                                                    <tr>
                                                                        <th>CRV</th>
                                                                        <th>Target ACOS</th>
                                                                    </tr>
                                                                    {
                                                                        el?.dynamicPPtargetAcos && el?.dynamicPPtargetAcos?.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr colSpan={8} key={`dynamicPPtargetAcos_${index}`} >
                                                                                        <td>
                                                                                            {item.from} - {item.to} %
                                                                                        </td>
                                                                                        <td><input type='number' data-name={item._id} value={item.Acos} onChange={(e) => changeHandler(e, item, "dynamicPPtargetAcos")} /></td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </table>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </table>
                    </div>
                </div>


                <div className='data_content data_content_btn mt-3'>
                    <div className='data_modal_btn'>
                        <h5>Sponsored Brands (Set Default Target ACOS) </h5>
                    </div>
                    <div className=' py-4 px-3'>

                        <table>
                            <tr>
                                <th>Type</th>
                                <th>Acos Value</th>
                                <th>Action</th>

                            </tr>
                            {
                                sb?.map(el => {
                                    return (
                                        <tr>
                                            <td><b>{el.type} </b></td>
                                            <td><input type='number' data-name={el.targetAcos} value={el.targetAcos} onChange={(e) => changeHandler(e, el, "targetAcos")} /></td>
                                            <td className='save_btn'><button onClick={() => SBsaveHandler(el, "Sponsored Brands")}>Save</button></td>

                                        </tr>
                                    )
                                })
                            }
                        </table>


                    </div>
                </div>

                <div className='data_content data_content_btn mt-3'>
                    <div className='data_modal_btn'>
                        <h5>Sponsored Display (Set Default Target ACOS) </h5>
                    </div>
                    <div className=' py-4 px-3'>

                        <table>
                            <tr>
                                <th>Type</th>
                                <th>Acos Value</th>
                                <th>Action</th>

                            </tr>
                            {
                                sd?.map(el => {
                                    return (
                                        <tr>
                                            <td><b>{el.type} </b></td>
                                            <td><input type='number' data-name={el.targetAcos} value={el.targetAcos} onChange={(e) => changeHandler(e, el, "targetAcos")} /></td>
                                            <td className='save_btn'><button onClick={() => SBsaveHandler(el, "Sponsored Display")}>Save</button></td>

                                        </tr>
                                    )
                                })
                            }
                        </table>


                    </div>
                </div>
            </div>


        </>


    )
}

export default ACOS