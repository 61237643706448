import React, { useEffect, useState } from 'react'
import { ApiCreateAverageAOV, ApiUpdateAverageAOV } from '../../../../api-wrapper/setting/ApiSettings';
import { Toast, useForm } from '../../../../helper/links/link';

function AOVModal({ addAov, setaddModalFlag, editId, flag, getAOV, profileId, setgetAOVlist }) {
  const {
    formState: { errors },
    register,
    reset
  } = useForm({});

  useEffect(() => {
    if (editId) {
      setMainAOVData([editId])
    } else {
      setMainAOVData([
        {
          campaignName: "",
          value: "",
          COGS: ""
        },
      ])
    }

    if (addAov) {
      setMainAOVData([
        {
          campaignName: "",
          value: "",
          COGS: ""
        },
      ])
    }

  }, [editId, addAov])


  const [mainAOVData, setMainAOVData] = useState([
    {
      campaignName: "",
      value: "",
      COGS: ""
    },
  ]);


  const submitHandler = async () => {
    const data = {
      profileId: profileId,
      campaignName: mainAOVData[0].campaignName,
      value: mainAOVData[0].value,
      COGS: mainAOVData[0].COGS
    }
    if (!addAov) {
      await ApiUpdateAverageAOV(editId._id, data)
        .then((e) => {
          if (e?.isSuccess) {
            Toast.success(e?.message);
            setgetAOVlist(true)
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((e) => {
          Toast.error("Somthing went wrong");
        });
    }
    else {
      await ApiCreateAverageAOV(data)
        .then((e) => {
          if (e?.isSuccess) {
            Toast.success(e?.message);
            setgetAOVlist(true)
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((e) => {
          Toast.error("Somthing went wrong");
        });
    }
    setaddModalFlag(false)
  }

  const mainAOVChangeHandler = (e, obj, address_type) => {
    if (address_type) {
      obj[address_type] = e.target.value;
    } else {
      obj[e.target.attributes["data-name"].value] = e.target.value;
    }
    setMainAOVData([...mainAOVData]);
  };




  return (
    <form >
      <div className='dotted_border pb-1'>
        <div className='add_filter'>
          <div className=' dotted_border'>
            <div className='form_group col-12 plus_filter d-flex align-items-center justify-content-between my-2 px-3'>
              <label className='pb-0'>Set AOV: </label>

            </div>
            {
              mainAOVData?.map((element, index) => {
                return (
                  <div className="row mb-2 px-3">
                    <div className="col col-12 mb-2" >
                      <input type='text'
                        placeholder='Enter Campaign Name'
                        name='campaignName'
                        value={element.campaignName}
                        {...register(`main.${index}.campaignName`, {
                          onChange: (e) =>
                            mainAOVChangeHandler(e, element, "campaignName"),
                        })}
                      />
                    </div>
                    <div className="col col-12 mb-2" >
                      <input type='number'
                        placeholder='Enter AOV'
                        name='value'
                        value={element.value}
                        {...register(`main.${index}.value`, {
                          onChange: (e) =>
                            mainAOVChangeHandler(e, element, "value"),
                        })}
                      />
                    </div>
                    <div className="col col-12 mb-2" >
                      <input type='number'
                        placeholder='Enter COGS'
                        name='value'
                        value={element.COGS}
                        {...register(`main.${index}.COGS`, {
                          onChange: (e) =>
                            mainAOVChangeHandler(e, element, "COGS"),
                        })}
                      />
                    </div>
                  </div>
                )
              })
            }
          </div>

        </div>
        <div className='form_btn mt-3 text-center'>
          <button className="cancel_btn px-2 py-1" type='button' style={{ height: '34px' }} onClick={() => setaddModalFlag(false)}>  Cancel </button>
          <button className="btn btn-primary px-2 py-1" type='button' onClick={() => submitHandler()} >Apply</button>
        </div>

      </div>
    </form>
  )
}

export default AOVModal