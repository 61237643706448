import { toast } from 'react-toastify';

class Toast {
    success(message) {
        toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })

    }

    error(message) {
        toast.error(message);
    }


}
export default new Toast();
