import * as types from './actionType'
const istate = {
    loader: false,
    profileInfo: '',
    sideMenu: {
        campaignFilter: false,
        customFilter: false
    },
    currentbid: []
}

const usersReducer = (state = istate, action) => {


    switch (action.type) {
        case types.GET_LOADER:
            return { ...state, loader: action.value }
        case 'PROFILE_INFO':
            return {
                ...state,
                profileInfo: action.value
            }
        case 'SIDEMENU_SHOW':
            return {
                ...state,
                sideMenu: action.value
            }
        case 'CHILD_BID':
            return {
                ...state,
                currentbid: action.value
            }
        default:
            return state;
    }
}

export default usersReducer;