import React, { useEffect, useState } from 'react'
import "rsuite/dist/rsuite.min.css";
import Filter from '../../../helper/filter/Filter';
import { handleLoader } from '../../../redux/action';
import { ApiHistory } from '../../../api-wrapper/otherHistory/History';
import { DataTable, useDispatch, Toast, moment, DateRangePicker } from '../../../helper/links/link';


function History() {
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [currentPage, setcurrentPage] = useState(1);
    const [timezone, setTimezone] = useState(
        localStorage.getItem("antonTimezone")
    );
    const [status, setStatus] = useState("");
    const [type, setType] = useState("Sponsored Brands");
    const [arg, setArg] = useState({ pageNo: 1, perPage: 25 });
    const [date, setDate] = useState([
        moment(new Date()).tz(timezone && timezone)?.format("YYYY-MM-DD"),
        moment(new Date()).tz(timezone && timezone)?.format("YYYY-MM-DD")]);

    const dispatch = useDispatch();

    const [campaignData, setcampaignData] = useState([]);


    const columns = [
        {
            name: "Run ",
            sortable: true,

            selector: (row) => `${row.isRun}`,
            cell: (e) => (
                <div className={`${e.isRun == 'PENDING' ? 'yellow_run' : e.isRun == 'PROCESSING' ? 'red_run' : ' green_run'} run`}>
                    {e.isRun}
                </div>
            )
        },
        {
            name: "Target Name",
            selector: (row) => row.targetName,
            sortable: true,
        },
        {
            name: "Bid ",
            sortable: true,
            selector: (row) => `$${row.bid}`,
        },
        {
            name: "created At",
            sortable: true,

            selector: (row) => row.createdAt,
            cell: (e) => (
                moment(e.createdAt)?.tz(timezone && timezone)?.format("YYYY-MM-DD hh:mm:ss A")
            )
        },
        {
            name: "updated At",
            sortable: true,

            selector: (row) => row.updatedAt,
            cell: (e) => (
                moment(e.updatedAt)?.tz(timezone && timezone)?.format("YYYY-MM-DD hh:mm:ss A")
            )
        },
    ]

    const campaignIdHandler = (e) => {
        // setcampaignId(e);
    };

    const dateHandler = (e) => {
        if (e) {
            setDate(e);
        }
    };

    const getCampaignLogHistory = async (arg) => {

        if (date) {
            dispatch(handleLoader(true));
            const data = {
                fromDate: moment(date && date[0]).format("YYYY-MM-DD"),
                toDate: moment(date && date[1]).format("YYYY-MM-DD"),
                pageNo: arg.pageNo,
                perPage: arg.perPage,
                status,
                type
            };
            await ApiHistory(data)
                .then((e) => {
                    if (e?.isSuccess) {
                        dispatch(handleLoader(false));
                        setcampaignData(e.data);
                        setTotalRecords(e.totalRecords)
                        setData([])
                    } else {
                        dispatch(handleLoader(false));
                        Toast.error(e?.message);
                    }
                })
                .catch((e) => {
                    dispatch(handleLoader(false));
                    Toast.error("Somthing went wrong");
                });
        }
    };

    useEffect(() => {
        getCampaignLogHistory(arg);
    }, [date, status, type]);


    const handlePerRowsChange = (newPerPage) => {
        setArg((prevArg) => ({ ...prevArg, perPage: newPerPage }));
        setRowsPerPage(newPerPage);
        getCampaignLogHistory({ ...arg, perPage: newPerPage });
    };

    const handlePageChange = (newPage) => {
        setArg((prevArg) => ({ ...prevArg, pageNo: newPage }));
        setcurrentPage(newPage);
        getCampaignLogHistory({ ...arg, pageNo: newPage });
    };

    return (
        <>
            <div className="bg-white">
                <Filter
                    dateShow={false}
                    campaignIdHandler={campaignIdHandler}
                />
            </div>

            <div className="middle_container heading_none">

                <div className="data_content">

                    <div className="data_table">
                        <div className='history-filter other_filter'>
                            <div>
                                <select
                                    name="portfolio"
                                    onChange={(e) => setStatus(e?.target?.value)}
                                    value={status}
                                >
                                    <option value="">All</option>
                                    <option value="PROCESSING">PROCESSING</option>
                                    <option value="UPDATED">UPDATED</option>
                                    <option value="PENDING">PENDING</option>
                                </select>
                            </div>
                            <div>
                                <select
                                    name="portfolio"
                                    onChange={(e) => setType(e?.target?.value)}
                                    value={type}
                                >
                                    <option value="Sponsored Brands">Sponsored Brands</option>
                                    <option value="Sponsored Display">Sponsored Display</option>

                                </select>
                            </div>
                            <div >
                                <DateRangePicker
                                    className="filter_date history_date"
                                    placeholder="Select Date"
                                    onChange={(e) => dateHandler(e)}
                                    cleanable={false}
                                    format="yyyy-MM-dd"
                                    value={[
                                        date &&
                                        date[0] !== undefined &&
                                        moment(date[0], "YYYY-MM-DD").toDate(),

                                        date &&
                                        date[1] !== undefined &&
                                        moment(date[1], "YYYY-MM-DD").toDate(),
                                    ]}
                                    placement="bottomEnd"
                                />
                            </div>
                        </div>

                        <DataTable
                            className="table_content mt-2"
                            columns={columns}
                            striped={true}
                            data={campaignData}
                            pagination
                            paginationServer
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationPerPage={rowsPerPage}
                            paginationTotalRows={totalRecords || 0}
                            paginationRowsPerPageOptions={[25, 100, 500, 1000]}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default History